<template>
  <v-row>
    <v-col cols="12" sm="2">
      <v-card dark color="accent" outlined class="pa-2" height="100%">
        <v-card-text>
          <v-row>
            <v-select
              :items="canalOptions"
              v-model="canalSelected"
              item-text="Nombre"
              item-value="CanalId"
              append-icon="keyboard_arrow_down"
              label="Canal"
              hint="Canal"
              persistent-hint
              hide-details
              multiple
            ></v-select>
          </v-row>
          <br />
          <br />
          <v-row>
            <v-select
              :items="gruposComision"
              v-model="grupoSelected"
              item-text="Nombre"
              item-value="AsociadosComisionGroupID"
              append-icon="keyboard_arrow_down"
              label="Grupo comisión"
              hint="Grupo comisión"
              persistent-hint
              hide-details
              multiple
            ></v-select>
          </v-row>
          <br />
          <br />
          <v-row>
            <v-select
              :items="periodoOptions"
              v-model="periodoSelected"
              item-text="NombreMes"
              item-value="FechaFin"
              append-icon="keyboard_arrow_down"
              label="Periodo"
              hint="Periodo"
              persistent-hint
              hide-details
            ></v-select>
          </v-row>
          <br />
          <br />
          <v-row>
            <v-select
              :items="productosTipos"
              v-model="tipoProductoSelected"
              item-text="ProductoName"
              item-value="ProductoTipoId"
              append-icon="keyboard_arrow_down"
              label="Tipo Producto"
              hint="Dejar vacio para filtrar por todos"
              persistent-hint
              multiple
            ></v-select>
          </v-row>
            <br />
          <br />
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" sm="10">
      <v-progress-linear
        indeterminate
        color="primary"
        v-if="processing"
      ></v-progress-linear>
      <v-tabs>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab>Gráfica</v-tab>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <div ref="amchart" style="height: 45vh"></div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
      <v-alert dense type="warning" text v-show="!processing && isChartEmpty"
        >No existe información para esta gráfica</v-alert
      >
    </v-col>
  </v-row>
</template>

<script>
import apiDashboard from "@/api/dashboards";
import apiHome from '@/api/home'
import apiEmpleado from "@/api/empleados";
import { mapActions } from "vuex";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themesAnimated from "@amcharts/amcharts4/themes/material";

am4core.useTheme(am4themesAnimated);

export default {
  props: ["tipoProductoOptions","grupoSeleted"],
  data() {
    return {
      processing: false,
      productosTipos:[],
      chartData: [],
      chart: null,
      grupoSelected: [],
      canalSelected:[],
      periodoSelected: null,
      tipoProductoSelected: [],
      gruposComision:[],
      canalOptions:[],
      headers: [
        { text: "PERIDO", value: "Periodo" },
        { text: "ASOCIADO", value: "Asociado" },
        { text: "CANAL", value: "Canal" },
      ],
    };
  },
  computed: {
    isChartEmpty() {
      return this.chartData.length === 0;
    },
    periodoOptions() {
      return this.$store.getters.periodos;
    },
    asociadoOptions() {
      return this.$store.getters.asociados.filter(
        (c) => this.grupoSelected.includes(c.CanalId)
      );
    },
    // canalOptions() {
    //   return this.$store.getters.canalesExternos;
    // },
    currentUser() {
      return this.$store.getters.user;
    },
    corteId() {
      return this.$store.getters.user.corteId;
    }
  },
  methods: {
    ...mapActions(["setError", "setErrorMessage", "setMessage"]),
    generateChart(dataParam) {
      if (this.chart) {
        this.chart.dispose();
      }

      try {
        let chart = am4core.create(this.$refs.amchart, am4charts.XYChart);
        chart.colors.step = 8;
        chart.legend = new am4charts.Legend();
        chart.exporting.menu = new am4core.ExportMenu();
        chart.numberFormatter.numberFormat = "#,###.##";
        chart.scrollbarX = new am4charts.XYChartScrollbar();

        let data = [];
        let incentivos = [];
        dataParam.forEach(function (row) {
          if (incentivos.indexOf(row.Incentivo) === -1) {
            incentivos.push(row.Incentivo);
          }
        });
      
        dataParam.forEach(function (d) {
          let newRow = {
            GrupoComision: d.GrupoComision,
            TotalLineas:0,
            Total: 0,
            Promedio: 0
          };
          let total = 0;
          let lineas = 0;
          let count = 0;

          incentivos.forEach(function (c) {
            let dc = dataParam.filter((f) => f.GrupoComision === d.GrupoComision && f.Incentivo === c);
            if (dc[0]) {
            
              const name = `${c}lineas`;

              newRow[c] = dc[0].Monto;
              newRow[name] = dc[0].Lineas;
              total += dc[0].Monto;
              lineas += dc[0].Lineas;
              count += 1;
            }
          });

          newRow["Total"] = total;
          newRow["TotalLineas"] = lineas;
          newRow["Promedio"] = count === 0 ? 0 : total / count;
          data.push(newRow);
        });

        chart.data = data;

        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "GrupoComision";
        categoryAxis.title.text = "GrupoComision";
        categoryAxis.renderer.grid.template.location = 0;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.minWidth = 35;

        incentivos.forEach(function (c) {

          const nameLineas = `${c}lineas`;

          let series = chart.series.push(new am4charts.ColumnSeries());
          series.dataFields.categoryX = "GrupoComision";
          series.dataFields.valueY = c;
          series.dataFields.valueYLineas = nameLineas;
          series.name = c;
          series.stacked = true;
          series.columns.template.width = am4core.percent(80);
          series.columns.template.tooltipText = "Lineas: {valueYLineas}\n{name}: [bold]{valueY}[/]";
        });

        let lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.dataFields.categoryX = "GrupoComision";
        lineSeries.dataFields.valueY = "Total";
        lineSeries.name = "Total";
        lineSeries.nameLineas = "TotalLineas";
        lineSeries.strokeWidth = 3;
        lineSeries.strokeLinejoin = "bevel"
        var circleBullet = lineSeries.bullets.push(
          new am4charts.CircleBullet()
        );
        circleBullet.circle.stroke = am4core.color("#fff");
        circleBullet.tooltipText = "Lineas: {TotalLineas}\n{name}: [bold]{valueY}[/]";

        //Promedio

        let lineSeriesPromedio = chart.series.push(new am4charts.LineSeries());
        lineSeriesPromedio.dataFields.categoryX = "GrupoComision";
        lineSeriesPromedio.dataFields.valueY = "Promedio";
        lineSeriesPromedio.name = "Promedio";
        lineSeriesPromedio.strokeWidth = 3;
        var circleBulletPromedio = lineSeriesPromedio.bullets.push(
          new am4charts.CircleBullet()
        );
        circleBulletPromedio.circle.stroke = am4core.color("#fff");
        circleBulletPromedio.tooltipText = "{name}: [bold]{valueY}[/]";

        this.chart = chart;
      } catch (e) {
        // empty
      }
    },
    refresh() {
      if (this.periodoSelected) {
        this.processing = true;
        let param = {
          FechaFin: this.periodoSelected,
          TiposProductos: this.tipoProductoSelected,
          GruposComisionId: this.grupoSelected,
        };

        apiDashboard
          .getChartComisionPorGruposComision(param)
          .then((response) => {
            this.generateChart(response.data);
            this.processing = false;
          })
          .catch((error) => {
            this.setError(error);
            this.processing = false;
          });
      }
    },

    getProductosTipos(){
      apiDashboard
          .getProductoTipos()
          .then((response) => {
            this.productosTipos = response.data
          })
          .catch((error) => {
            this.setError(error);
          });
    },
    async getGruposComision(){
      
      const response = await apiEmpleado.getGruposComision(Array.from(this.canalSelected));

      this.gruposComision = response.data
    },
    async getCanales(){

      let canales = [];

      if(this.grupoSeleted === 1){
       
        var id = this.corteId?this.corteId:0;

        const responseCanales =  await apiHome.getCanalesExternosGestionados(id);

        canales = responseCanales.data;


      }else if(this.grupoSeleted === 2){
        const responseCanales =  await apiHome.getCanalesInternos();

        canales = responseCanales.data;
      }

      this.canalOptions = canales;
    }
  },
  watch: {
    corteId: function () {
      this.getCanales();
    },
    grupoSelected: function () {
      this.refresh();
    },
    periodoSelected: function () {
      this.refresh();
    },
    tipoProductoSelected: function () {
      this.refresh();
    },
    canalSelected: function () {

        if(this.grupoSelected.length>0)
          this.grupoSelected = [];

        this.getGruposComision();
    },
    periodoOptions: function () {
      if (this.periodoOptions.length > 0) {
        this.periodoSelected = this.periodoOptions[0].FechaFin;
      }
    },
    grupoSeleted:function() {
      this.getCanales();
    }
  },
  mounted() {

    this.tipoProductoSelected = [];
    this.grupoSelected = [];

    if (this.periodoOptions.length > 0) {
      this.periodoSelected = this.periodoOptions[0].FechaFin;
    }
 
    this.getProductosTipos();
    this.refresh();
    this.getCanales();
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
};
</script>

<style scope>
.v-alert.v-sheet.theme--dark.v-alert--dense.warning {
  margin-bottom: 0;
}
</style>